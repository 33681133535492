<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png" @click="message">
    <HelloI18n msg="Welcome to Your Vue.js App"/>
    <el-calendar v-model="value" />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import HelloI18n from '@/components/HelloI18n.vue'
import TimeControl from '@/utils/time-control'

export default {
  name: 'Home',
  components: {
    HelloWorld,
    HelloI18n
  },
  data() {
    return {
      value: (new Date()),
    }
  },
  methods: {
    message() {
      // this.$message.error(this.$t('message'));
      let a = TimeControl.test()
      this.$message.success(a)
    }
  }
}
</script>
