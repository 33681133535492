<template>
  <p>{{ $t('message') }}</p>
  <h4>{{ msg }}</h4>
  <div @click="changeLocale('zh-cn')">中文</div>
  <div @click="changeLocale('en')">English</div>
</template>

<script>
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import storage from 'store'
import { setDocuemntTitle } from '@/utils/index.js'

export default defineComponent({
  name: 'HelloI18n',
  setup() {
    
    const { locale } = useI18n()

    function changeLocale(lang) {
      locale.value = lang
      storage.set('lang', lang)

      setDocuemntTitle(this.$route.meta)
    }

    return { changeLocale }
  },
  props: {
    msg: String
  }
})
</script>